import { connect } from 'react-redux';
import { expandHomeTimeline, updateTimelineSortBy } from '../../../../brighteon/actions/timelines';
import SortSelector from '../components/sort_selector';

const mapStateToProps = state => {
  return { sortBy: state.getIn(['timelines', 'home', 'sortBy']) };
};

const mapDispatchToProps = (dispatch) => ({
  handleUpdateSortBy(sortBy) {
    dispatch(updateTimelineSortBy('home', sortBy));
    dispatch(expandHomeTimeline());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SortSelector);
