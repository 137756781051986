import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../components/icon';

class SortSelector extends React.PureComponent {

  static propTypes = {
    sortBy: PropTypes.string,
    handleUpdateSortBy: PropTypes.func.isRequired,
  };

  state = {
    show: false,
  }

  showDropdownSelector = () => {
    // Show Dropdown Selector
    this.setState({ show: !this.state.show });
  }

  handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({ show: false });
    }
  }

  updateSortBy = (sortBy) => {
    this.setState({ show: !this.state.show });
    this.props.handleUpdateSortBy(sortBy);
  }

  handleSortClick = (e) => {
    this.updateSortBy(e.currentTarget.dataset.id);
  }

  render() {
    const options = [
      { key: 'newest-all', title: 'Newest All', description: 'Show newest posts first, including reposts.' },
      { key: 'newest-posts-only', title: 'Newest (posts only)', description: 'Show newest posts first. No reposts' },
      { key: 'top-posts', title: 'Top Posts', description: 'Show the highest-rated posts sorted by likes, reposts, and replies' },
    ];

    const sortBy = this.props.sortBy;
    let sortByTxt = '';
    options.forEach(option => {
      if (option.key === sortBy) {
        sortByTxt = option.title;
      }
    });

    const show = this.state.show;
    return (
      <div className='sort-selector-container' onBlur={this.handleBlur} tabIndex={0}>
        Sort by
        <div className='sort-selector'>
          <div onClick={this.showDropdownSelector} className='d-flex' role='button' tabIndex={0}>
            <span>{sortByTxt}</span>
            <Icon id='arrow-down' className='ml-2' size={16} />
          </div>
          {
            show && (
              <div className='sort-by-dropdown'>
                {
                  options.map((option, i) => {
                    return (
                      <div className='option' onClick={this.handleSortClick} key={i} role='button' tabIndex={0} data-id={option.key}>
                        <div className='icon-container'>
                          { option.key === sortBy &&
                            <Icon id='selected' className='ml-2' size={24} />
                          }
                        </div>
                        <div className='option-details'>
                          <div className='title'>{option.title}</div>
                          <div className='description'>{option.description}</div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            )
          }
        </div>
      </div>
    );
  }

}

export default SortSelector;
